export class LazyAnimate {
  constructor () {
    this.id = 0
    this.ojbk = []
    window.addEventListener('scroll', (e) => {
      if (this.ojbk.length) {
        for (const i in this.ojbk) {
          if (this.getElementTop(this.ojbk[i]) < window.scrollY) {
            this.ojbk[i].classList.add('lazy-animate-run')
            delete this.ojbk[i]
          }
        }
      }
    })
  }

  bind (el, args) {
    el.classList.add('lazy-animate-init')
    if (args) el.style.transitionDelay = `${args}s`
  }

  join (el, args) {
    el.setAttribute('data-lazy-id', ++this.id)
    if (this.getElementTop(el) < window.scrollY) {
      el.classList.add('lazy-animate-run')
    } else {
      this.ojbk[this.id] = el
    }
  }

  remove (el) {
    const id = el.getAttribute('data-lazy-id')
    delete this.ojbk[id]
  }

  getElementTop (el) {
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = el.getBoundingClientRect()
    return elemRect.top - bodyRect.top - document.documentElement.clientHeight + 30
  }
}
